import React, { useEffect, useState } from 'react'
import { MenuPlacement } from 'react-select'

import { Accordion } from '../..'
import { Dropdown } from '../../form/fields'
import { Option } from '../../form/fields/dropdown/types'

interface Props {
   className?: string
   defaultValue?: Option[]
   id: string
   isMulti?: boolean
   onChange: (name: string, label: string, value: Option[]) => void
   options: Option[]
   title: string
   menuPlacement?: MenuPlacement
}

const DropdownFilter: React.FC<Props> = ({
   className,
   defaultValue,
   id,
   isMulti,
   options,
   onChange,
   title,
   menuPlacement = 'bottom',
}) => {
   const [isOpenInitally, setIsOpenInitially] = useState<boolean>(false)
   const handleFilterChange = (options: Option[]) => {
      onChange(id, '', options)
   }

   useEffect(() => {
      setIsOpenInitially(!!defaultValue?.length)
   }, [])

   return (
      <Accordion
         className={className}
         id={id}
         isOpen={isOpenInitally}
         title={title}
      >
         <Dropdown
            defaultValue={defaultValue || []}
            isMulti={isMulti}
            id={id}
            name={id}
            onChange={handleFilterChange}
            options={options}
            noMargin
            withWrappers={false}
            menuPlacement={menuPlacement}
         />
      </Accordion>
   )
}

export default DropdownFilter
