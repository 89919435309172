import { ROUTES } from '../../routes'

export const helpNavList = [
   {
      external: true,
      label: 'Search Help',
      route: ROUTES.help_search, //'https://help.sharemytoolbox.com/knowledge',
   },
   {
      external: true,
      label: 'Contact Us',
      route: ROUTES.help_contact, //'https://share.hsforms.com/1Rk5iaBX7TdyF-6cpXPtxdg4xbgj'
   },
   {
      external: true,
      label: 'Buy Asset Tags',
      route: ROUTES.help_asset_tags, // 'https://www.etsy.com/shop/ShareMyToolbox'
   },
]
