// extracted by mini-css-extract-plugin
export var actionButton = "slidePanel-module--action-button--70e34";
export var actionButtonReset = "slidePanel-module--action-button-reset--d8684";
export var close = "slidePanel-module--close--004ee";
export var content = "slidePanel-module--content--652c0";
export var header = "slidePanel-module--header--056ed";
export var panel = "slidePanel-module--panel--c0f7a";
export var panelOpen = "slidePanel-module--panel-open--3f0ee";
export var title = "slidePanel-module--title--20f16";
export var titleIcon = "slidePanel-module--title-icon--c40cd";
export var titleWrapper = "slidePanel-module--title-wrapper--86309";
export var wrapper = "slidePanel-module--wrapper--387c5";