import React, { ChangeEvent, memo, useEffect, useState } from 'react'

import { FieldWrapper } from '..'
import * as styles from './textarea.module.scss'
import { TextAreaProps } from './types'

const Textarea = ({
   className,
   description,
   disabled = false,
   error,
   id,
   inputProps,
   label,
   noMargin = false,
   onChange,
   required = false,
   withWrappers = true,
   value,
   isReadonly = false,
   height = 100,
}: TextAreaProps) => {
   const [inputHasFocus, setInputHasFocus] = useState<boolean>(false)

   const handleInputFocus = () => setInputHasFocus(true)

   const handleInputBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (!event.target.value) {
         setInputHasFocus(false)
      }
   }

   const renderElement = () => (
      <textarea
         {...(inputProps || {})}
         defaultValue={value}
         className={styles.field}
         aria-invalid={!!error}
         name={id}
         onBlur={handleInputBlur}
         onChange={onChange}
         onFocus={handleInputFocus}
         disabled={disabled || isReadonly}
         style={{ height }}
      />
   )

   useEffect(() => {
      if (!value && parseInt(String(value)) !== 0) {
         setInputHasFocus(false)
      } else {
         setInputHasFocus(true)
      }
   }, [value])

   if (!withWrappers) {
      return renderElement()
   }

   return (
      <FieldWrapper
         className={className}
         description={description}
         disabled={disabled}
         error={error}
         hasFocus={inputHasFocus}
         id={id}
         label={label}
         required={required}
         noMargin={noMargin}
      >
         {renderElement()}
      </FieldWrapper>
   )
}

export default memo(Textarea)
