import React, { useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'

import * as styles from './image.module.scss'

const Image = ({
   alt,
   className,
   defaultImagePadding,
   height,
   padding = 0,
   url,
   width,
   lazyLoading,
}: ImageProps) => {
   const placeholderSrc = '/images/placeholder.svg'
   const [hasError, setHasError] = useState<boolean>(false)

   const imageHeight = useMemo(() => height ?? width, [height, width])

   const showPlaceholder = useMemo(() => hasError || !url, [hasError, url])

   const imagePadding = useMemo(
      () => (showPlaceholder ? defaultImagePadding : padding),
      [padding, defaultImagePadding, showPlaceholder]
   )

   const loading = useMemo(
      () => (lazyLoading ? 'lazy' : 'eager'),
      [lazyLoading]
   )

   const onError = useCallback(() => setHasError(true), [])

   return (
      <span
         className={classNames(styles.wrapper, className)}
         style={{
            height: imageHeight,
            padding: imagePadding,
            width,
         }}
      >
         <img
            className={styles.image}
            src={showPlaceholder ? placeholderSrc : url}
            alt={alt}
            loading={loading}
            onError={onError}
         />
      </span>
   )
}

export default Image
