import { ItemStatus } from '../../../../hooks/useItemStatus/useItemStatus.type'
import { Item } from '../../../../hooks/useUserItems/useUserItems.type'
import { Connection } from '../../../../hooks/useConnections/types'
import { Manufacturer } from '../../../../hooks/useManufacturers/useManufacturers.type'
import { Category } from '../../../../hooks/useCategories/useCategories.type'
import { Location } from '../../../../types'
import { Condition } from '../../../../hooks/useConditions/useConditions.type'
import { Type as ItemType } from '../../../../hooks/useItemTypes/useItemTypes.type'
import { NoteType } from '../../../../hooks/useNoteTypes/useNoteTypes.type'
import { Option } from '../../form/fields/dropdown/types'
import { TableSortColumnOption } from '../../../../hooks/useTableSortColumnOptions/useTableSortColumnOptions.type'

export const convertItemStatusesToOptions = (
   statuses: ItemStatus[]
): Option[] =>
   statuses?.length > 0
      ? statuses.map((status) => ({ value: status.Value, label: status.Text }))
      : []

export const convertUserItemsToOptions = (items: Item[]): Option[] =>
   items?.length > 0
      ? items.map((item) => ({ value: item.ID, label: item.Title }))
      : []

export const convertUserItemsToNoteDropdownOptions = (
   items: Item[],
   settings: any
): Option[] => {
   return items.map((item) => {
      if (item.SerialNumber && item.SerialNumber[0] === '-')
         item.SerialNumber = item.SerialNumber.replace('-', '')
      const custom =
         settings.CustomText1Enabled && item.CustomText1Value
            ? `| ${settings.CustomText1Label} ${item.CustomText1Value}`
            : ''
      const serialNumber = item.SerialNumber
         ? `| Serial # ${item.SerialNumber}`
         : ''
      const barcode = item.Barcode ? `| Barcode ${item.Barcode}` : ''
      return {
         value: item.ID,
         label: `${item.Title} ${serialNumber} ${barcode} ${custom}`,
      }
   })
}

export const convertConnectionsToOptions = (
   connections: Connection[]
): Option[] =>
   connections?.length > 0
      ? connections
           .filter((connection) => String(connection.ID) !== '0')
           .map((connection) => ({
              value: connection.ID,
              label: connection.DisplayName,
           }))
      : []

export const convertManufacturersToOptions = (
   manufacturers: Manufacturer[]
): Option[] =>
   manufacturers?.length > 0
      ? manufacturers.map((manufacturer) => ({
           value: manufacturer.Id.toString(),
           label: manufacturer.Name,
        }))
      : []

export const convertCategoriesToOptions = (
   categories: Category[]
): Option[] => {
   const categoriesToReturn =
      categories?.length > 0
         ? categories.map((category) => ({
              value: category.Id.toString(),
              label: category.Name,
           }))
         : []
   categoriesToReturn.push({ label: 'Uncategorized', value: '82' })
   return categoriesToReturn
}

export const convertLocationToOptions = (locations: Location[]): Option[] =>
   locations?.length > 0
      ? locations.map((location) => ({
           value: location.Id.toString(),
           label: location.Description,
        }))
      : []

export const convertConditionsToOptions = (conditions: Condition[]): Option[] =>
   conditions?.length > 0
      ? conditions.map((condition) => ({
           value: condition.Value,
           label: condition.Text,
        }))
      : []

export const convertItemTypesToOptions = (items: ItemType[]): Option[] =>
   items?.length > 0
      ? items.map((item) => ({ value: item.Value, label: item.Text }))
      : []

export const convertItemStatusStatesToOptions = (
   items: { label: string; status: number }[]
): Option[] =>
   items?.length > 0
      ? items.map((item) => ({
           value: item.status.toString(),
           label: item.label,
        }))
      : []

export const convertNoteTypesToOptions = (noteTypes: NoteType[]): Option[] =>
   noteTypes?.length > 0
      ? noteTypes.map((noteType) => ({
           value: noteType.Id.toString(),
           label: noteType.Title,
        }))
      : []

export const convertSelectKitToOptions = (selectKits: []): Option[] =>
   selectKits?.length > 0
      ? selectKits.map((selectKit: any) => ({
           value: selectKit.Id.toString(),
           label: selectKit.Title,
        }))
      : []

export const convertToolStatusToOptions = (
   items: { label: string; status: number }[]
): Option[] =>
   // suffix added to tool status due to duplicate values
   items?.length > 0
      ? items.map((item, i) => ({
           value: item.status.toString() + '_' + i,
           label: item.label,
        }))
      : []

export const convertTableSortColumnOptionsListToOptions = (
   columnSortOptions: TableSortColumnOption[]
) =>
   columnSortOptions?.length > 0
      ? columnSortOptions.map((item) => ({
           value: item.Value,
           label:
              item.Key === 'Assigned To User' ? 'Loaned/Pending To' : item.Key,
        }))
      : []
