import React from 'react'

import { Row, Col } from 'react-grid-system'

import Dropdown from '../../common/form/fields/dropdown/dropdown'
import DateInput from '../../common/form/fields/dateInput/dateInput'
import { LoanAndFieldTransferViewProps } from '../actionsWorkflow.type'

export const LoanAndFieldTransferView = ({
   validationErrors,
   handleDropdownChange,
   connectionOptions,
   locationOptions,
   handleDateChange,
   cartOptions,
   settings,
}: LoanAndFieldTransferViewProps) => (
   <>
      <Row gutterWidth={20}>
         <Col md={6} lg={6}>
            <Dropdown
               error={validationErrors?.Connection}
               name="Connection"
               onChange={(option) => handleDropdownChange('Connection', option)}
               options={connectionOptions}
               id="Connection"
               label="Connection"
               placeholder="Connection"
               isMulti={false}
               required
            />
         </Col>
         <Col md={6} lg={6}>
            <DateInput
               id="DueBackDate"
               label="Due back (optional)"
               onChange={(date) => handleDateChange('DueBack', date)}
               selectedDate={
                  cartOptions?.dueBackDate
                     ? new Date(Date.parse(cartOptions.dueBackDate))
                     : null
               }
               minDate={new Date()}
            />
         </Col>
      </Row>
      <Row gutterWidth={20}>
         <Col md={6} lg={6}>
            {settings.EnableLocationTracking && (
               <Dropdown
                  name="NewLocation"
                  onChange={(option) =>
                     handleDropdownChange('Location', option)
                  }
                  options={locationOptions}
                  id="NewLocation"
                  label="New Location (optional)"
                  placeholder="New Location (optional)"
                  isMulti={false}
               />
            )}
         </Col>
         <Col md={6} lg={6}></Col>
      </Row>
   </>
)
