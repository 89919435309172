import React, { useEffect, useState } from 'react'
import * as styles from '../checkoutModal.module.scss'
import { SuccessViewProps, View } from '../actionsWorkflow.type'

export const SuccessView = ({
   itemCount = 1,
   view,
   connectionName,
   dueBackDate,
   isAdminCompanyUser,
}: SuccessViewProps) => {
   const [successMsg, setSuccessMsg] = useState<string>('')
   useEffect(() => {
      switch (view) {
         case View.Loan: {
            const dueBackMsg =
               dueBackDate !== '' ? `and will be due back ${dueBackDate}` : ''
            const loanMsg = `Your ${itemCount} ${
               itemCount > 1 ? 'items were' : 'item was'
            } Loaned to ${connectionName} ${dueBackMsg}`
            setSuccessMsg(loanMsg)
            break
         }
         case View.Return: {
            const returnMsg = isAdminCompanyUser
               ? 'Your Return request has been completed.'
               : 'Your Return request has been sent. We will notify you once the request has been accepted.'
            setSuccessMsg(returnMsg)
            break
         }
         case View.Field_Transfer:
         case View.Warehouse_Transfer: {
            const transferMsg = isAdminCompanyUser
               ? 'Your Transfer request has been sent.'
               : 'Your Transfer is complete.'
            setSuccessMsg(transferMsg)
            break
         }
         case View.Borrow: {
            const borrowMsg =
               'Your Borrow request has been sent. We will notify you once the request has been accepted.'
            setSuccessMsg(borrowMsg)
            break
         }
         case View.MakeAvailable:
         case View.MakeUnavailable: {
            const availableMsg = `Your ${itemCount} ${
               itemCount > 1 ? 'items were' : 'item was'
            } made ${
               view === View.MakeAvailable ? 'Available' : 'Unavailable'
            }.`
            setSuccessMsg(availableMsg)
            break
         }
         default:
            return
      }
   }, [view])

   return (
      <div className={styles.centeredViewWrapper}>
         <div className={styles.centeredViewHeading}>Nice!</div>
         <p className={styles.centeredViewMessage}>{successMsg}</p>
      </div>
   )
}
