import React, { memo } from 'react'

const ToggleInfo = ({ name, value, hideEnabledDisabled }: ToggleInfoProps) => (
   <div className={`toggle-info toggle-info-${value ? '' : 'disabled'}`}>
      {name}
      {!hideEnabledDisabled && (
         <>
            <br />
            {value ? 'Enabled' : 'Disabled'}
         </>
      )}
   </div>
)

export default memo(ToggleInfo)
