import React, { memo, useCallback, useMemo, useState } from 'react'
import * as styles from './matchedImage.module.scss'
import { MatchedImageProps } from './types'
import Svg from '../svg/svg'

const MatchedImage = ({ image, onClick, disabled }: MatchedImageProps) => {
   const { PictureFullURL: src, selected, Position } = image

   const placeholderSrc = '/images/placeholder.svg'
   const [imageSrc, setImageSrc] = useState<string>(src ?? placeholderSrc)

   const stateClass = useMemo(() => {
      if (selected) {
         return styles.selectedImage
      } else if (disabled) {
         return styles.disabledImage
      } else {
         return ''
      }
   }, [selected, disabled])

   const onError = useCallback(
      () => (src !== placeholderSrc ? setImageSrc(placeholderSrc) : null),
      []
   )

   return (
      <article
         className={`${styles.matchedImage} ${stateClass}`}
         onClick={() => onClick(image)}
         role="button"
         tabIndex={Position}
      >
         <div className={styles.checkBox}>
            <Svg id="tick" fill="white" />
         </div>
         <picture>
            <source srcSet={imageSrc} type="image/webp" />
            <source srcSet={imageSrc} type="image/png" />
            <source srcSet={imageSrc} type="image/jpeg" />
            <source srcSet={imageSrc} type="image/svg+xml" />
            <img
               alt={`Matched ${Position + 1}`}
               src={imageSrc}
               loading="lazy"
               onError={onError}
            />
         </picture>
         <div className={styles.icon}>
            <Svg id="matchedImage" fill="white" />
         </div>
      </article>
   )
}

export default memo(MatchedImage)
