// @refresh reset
import React, { memo, useEffect, useMemo, useState } from 'react'
import * as styles from './itemGPSView.module.scss'
import ItemGPSMap from '../itemGPSMap/itemGPSMap'
import { Col, Row } from 'react-grid-system'
import classNames from 'classnames'
import LocationKeyValue from '../locationKeyValue/locationKeyValue'
import useGpsBluetooth from '../../../../../hooks/useGpsBluetooth/useGpsBluetooth'
import { formatLocaleDate } from '../../../../../utils/functions'
import { GpsLocation } from '../../../../../hooks/useGpsBluetooth/useGpsBluetooth.type'
import LoadingOverlay from '../../../loadingOverlay/loadingOverlay'
import MapLegend from '../mapLegend/mapLegend'

const ItemGPSView = ({ selectedItem }: ItemGPSViewProps) => {
   const [isGpsChecked, setIsGpsChecked] = useState<boolean>(true)
   const [isBluetoothChecked, setIsBluetoothChecked] = useState<boolean>(false)
   const [selectedLocation, setSelectedLocation] = useState<GpsLocation | any>(
      null
   )
   const [isMapZooming, setIsMapZooming] = useState<boolean>(false)
   const [isInfoCardOpen, setIsInfoCardOpen] = useState<boolean>(false)

   const {
      getToolGpsLocations,
      gpsLocationsList,
      isLoadingLocationsList,
      isRefetchingLocationsList,
   } = useGpsBluetooth()

   useEffect(() => {
      getToolGpsLocations(selectedItem?.Id)

      return () => getToolGpsLocations(null)
   }, [selectedItem])

   const showCurrentLocation = useMemo(
      () =>
         selectedItem.CurrentLocation &&
         selectedItem.Latitude &&
         selectedItem.Longitude
            ? true
            : false,
      [selectedItem]
   )

   const showLocationsSection = useMemo(
      () => gpsLocationsList?.length > 0 || showCurrentLocation,
      [showCurrentLocation, gpsLocationsList]
   )

   const showChecks = useMemo(
      () => !!selectedItem?.GPS && !!selectedItem?.Bluetooth,
      [selectedItem]
   )

   const handleCheckBoxToggle = (value: string) =>
      value === 'GPS'
         ? setIsGpsChecked(!isGpsChecked)
         : setIsBluetoothChecked(!isBluetoothChecked)

   const selectedLocationToCurrentLocation = () => {
      if (selectedItem.localUniqueId !== selectedLocation?.ID) {
         setIsInfoCardOpen(false)
         setSelectedLocation({
            CurrentLocation: selectedItem.CurrentLocation,
            ID: selectedItem.localUniqueId,
            Latitude: selectedItem.Latitude,
            Longitude: selectedItem.Longitude,
         })
      }
   }

   const selectedLocationToScanLocation = (location: GpsLocation) => {
      if (location.ID !== selectedLocation?.ID) {
         setIsInfoCardOpen(false)
         setSelectedLocation(location)
      }
   }

   const handleCloseInfoCard = () => {
      setIsInfoCardOpen(false)
      setSelectedLocation(null)
   }

   const handleOpenInfoCard = () => setIsInfoCardOpen(true)

   return (
      <section>
         {(isLoadingLocationsList || isRefetchingLocationsList) && (
            <LoadingOverlay />
         )}
         <Row gutterWidth={15}>
            <MapLegend
               isGpsChecked={isGpsChecked}
               isBluetoothChecked={isBluetoothChecked}
               handleCheckBoxToggle={handleCheckBoxToggle}
               showChecks={showChecks}
            />
            <Col
               sm={showLocationsSection ? 7 : 12}
               lg={showLocationsSection ? 8.5 : 12}
               className={styles.mapArea}
            >
               <div className={styles.mapContent}>
                  {!isLoadingLocationsList && !isRefetchingLocationsList && (
                     <ItemGPSMap
                        selectedItem={selectedItem}
                        gps_bt_locationsList={gpsLocationsList}
                        isLoadingLocationsList={isLoadingLocationsList}
                        selectedLocation={selectedLocation}
                        selectedLocationToCurrentLocation={
                           selectedLocationToCurrentLocation
                        }
                        selectedLocationToScanLocation={
                           selectedLocationToScanLocation
                        }
                        isInfoCardOpen={isInfoCardOpen}
                        handleCloseInfoCard={handleCloseInfoCard}
                        handleOpenInfoCard={handleOpenInfoCard}
                        setIsMapZooming={setIsMapZooming}
                     />
                  )}
               </div>
            </Col>
            {showLocationsSection && (
               <Col sm={5} lg={3.5} className={styles.locationsWrapper}>
                  <div className={styles.locationsListHeader}>
                     Up to Last 20 Known
                     <br />
                     Locations
                  </div>
                  <div>Tap to view on map.</div>
                  <div
                     className={classNames(styles.locationsList, {
                        [styles.locationsListDisabled]: isMapZooming,
                     })}
                  >
                     {showCurrentLocation && (
                        <>
                           <div>
                              <LocationKeyValue
                                 label={selectedItem?.CurrentLocation ?? 'N/A'}
                                 value={''}
                                 svgId={'pinNoShadow'}
                                 svgFill={'#FFCA05'}
                                 svgWidth={16}
                                 onClick={selectedLocationToCurrentLocation}
                              />
                           </div>
                           <hr />
                        </>
                     )}

                     {!isLoadingLocationsList &&
                        gpsLocationsList.map((location) => {
                           return (
                              <div key={`${location.ToolID}_${location.ID}`}>
                                 <LocationKeyValue
                                    label={location.CreatedByName}
                                    value={formatLocaleDate(location.CreatedOn)}
                                    svgId={'gps'}
                                    onClick={() =>
                                       selectedLocationToScanLocation(location)
                                    }
                                 />
                              </div>
                           )
                        })}
                  </div>
               </Col>
            )}
         </Row>
      </section>
   )
}

export default memo(ItemGPSView)
