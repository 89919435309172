import React, { ChangeEvent, useEffect, useState } from 'react'
import * as yup from 'yup'

import { Accordion, SlidePanel } from '../common'

import { DropdownFilter, DateRangeFilter } from '../common/filters/filterTypes/'
import {
   Filters as FiltersObject,
   FiltersForAPI,
} from '../../hooks/useFilters/useFilters.type'
import { Option } from '../common/form/fields/dropdown/types'
import {
   useActiveLocations,
   useCategories,
   useConnections,
   useFilters,
   useItemTypes,
   useManufacturers,
   useSettings,
} from '../../hooks'
import {
   convertCategoriesToOptions,
   convertConnectionsToOptions,
   convertItemTypesToOptions,
   convertLocationToOptions,
   convertManufacturersToOptions,
   convertToolStatusToOptions,
} from '../common/filters/helpers'
import { Select } from '../common/form/fields'
import { format } from 'date-fns'
import { FORMATS } from '../../constants/datetime'
import { flattenFilterObj } from '../../hooks/useFilters/useFilters.helpers'
import { ACTION_CONSTANTS } from '../views/actions/constants'
import { ActionsFilterPanelProps } from './actionsWorkflow.type'

const { quickFilterOptions, quickFilterEmployeeOptions } = ACTION_CONSTANTS

const ActionsFilterPanel = ({
   isFilterPanelOpen,
   handleClose,
   applySavedFilters,
   isPageFilterReset,
   setIsPageFilterReset,
   isEmployeeUser,
   selectedActionQuickFilter,
}: ActionsFilterPanelProps) => {
   const { settings } = useSettings()
   const schema = yup.object().shape({})

   const [defaultFilterValue, setDefaultFilterValue] = useState({
      toolStatus: { label: '', value: selectedActionQuickFilter.status },
   })
   const [defaultSuffix, setDefaultSuffix] = useState('')

   const { modify, reset, save, tmpFilters } = useFilters(schema, {
      toolStatus: { label: '', value: defaultSuffix },
   })

   const { list: itemTypes } = useItemTypes()
   const { list: connections } = useConnections()
   const { list: activeLocations } = useActiveLocations()
   const { list: manufacturers } = useManufacturers()
   const { list: categories } = useCategories()
   ////////////////////////////////////////////////////////////////////////////////////////////
   //tool status options has a suffix added to allow for duplicate values on dropdown options//
   // these are then removed before filters are applied to allow for the prepare for api     //
   //method the suffix is passed back up to the actions page via the applySavedFilters method//
   // where it is used to match back up with the quick filter options                        //
   ////////////////////////////////////////////////////////////////////////////////////////////
   const toolStatusOptions = convertToolStatusToOptions(
      !isEmployeeUser ? quickFilterOptions : quickFilterEmployeeOptions
   )
   const itemTypeOptions = convertItemTypesToOptions(itemTypes)
   const connectionOptions = convertConnectionsToOptions(connections)
   const locationOptions = convertLocationToOptions(activeLocations)
   const manufacturerOptions = convertManufacturersToOptions(manufacturers)
   const categoryOptions = convertCategoriesToOptions(categories)

   // listen for overall page reset
   useEffect(() => {
      if (isPageFilterReset) {
         handleResetFilters()
         setIsPageFilterReset(false)
      }
   }, [isPageFilterReset])

   useEffect(() => {
      // needed to link quick filters to toolStatus options and immediately set a tool status based on selected quick filter
      modify(
         'toolStatus',
         '',
         toolStatusOptions[selectedActionQuickFilter.id].value
      )
      // defaults used on reset
      setDefaultFilterValue({
         toolStatus: { label: '', value: selectedActionQuickFilter.status },
      })
      setDefaultSuffix(toolStatusOptions[selectedActionQuickFilter.id].value)
   }, [selectedActionQuickFilter])

   function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
      function formatDate(date: Date) {
         return format(date, FORMATS.filterDateAPI)
      }

      const startDateFilter = {
         ...(filters?.startDate?.value instanceof Date && {
            startDate: formatDate(filters?.startDate?.value),
         }),
      }
      const endDateFilter = {
         ...(filters?.endDate?.value instanceof Date && {
            endDate: formatDate(filters?.endDate?.value),
         }),
      }
      return {
         ...flattenFilterObj(filters),
         ...startDateFilter,
         ...endDateFilter,
      }
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[]
   ) => {
      const filterOptions = Array.isArray(options)
         ? options?.map((option) => ({
              label: option.label,
              value: option.value,
           }))
         : [options]
      modify(name, label, filterOptions)
   }

   const handleSelectChange = (event: ChangeEvent<HTMLFormElement>) => {
      const { name, value } = event.target
      modify(name, '', value)
   }

   const handleResetFilters = () => {
      reset()
      applySavedFilters(formatFiltersForAPI(defaultFilterValue), defaultSuffix)
   }

   const removeSuffixFromToolStatusValue = (tmpFilters: FiltersObject) => {
      return {
         ...tmpFilters,
         toolStatus: {
            label: '',
            value: tmpFilters.toolStatus.value.split('_')[0],
         },
      }
   }

   const handleFilterSave = async () => {
      const isSaved = await save()
      if (isSaved) {
         // should now always have suffix
         const suffix = tmpFilters?.toolStatus?.value
         const adjustedTmpFilters = removeSuffixFromToolStatusValue(tmpFilters)
         // remove tool status suffix added for duplicate values before passing to api pass the suffix as second param to match to quick filter
         applySavedFilters(formatFiltersForAPI(adjustedTmpFilters), suffix)
      }
   }

   return (
      <SlidePanel
         variant="Filters"
         isOpen={isFilterPanelOpen}
         onClose={handleClose}
         onReset={handleResetFilters}
         onSave={handleFilterSave}
      >
         <DropdownFilter
            defaultValue={tmpFilters?.itemTypes?.value as Option[]}
            isMulti
            id="itemTypes"
            onChange={handleDropdownChange}
            options={itemTypeOptions}
            title="Type"
         />
         <Accordion title="Actions">
            <Select
               options={toolStatusOptions.map((status, i) => ({
                  key: status.value,
                  value: status.label,
               }))}
               id="toolStatus"
               onChange={handleSelectChange}
               value={tmpFilters?.toolStatus?.value as string}
               isControlled
               hideDefaultOption
               noMargin
            />
         </Accordion>
         <DropdownFilter
            defaultValue={tmpFilters?.selectedConnections?.value as Option[]}
            isMulti
            id="selectedConnections"
            onChange={handleDropdownChange}
            options={connectionOptions}
            title="Connections"
         />
         {!!settings?.EnableLocationTracking && (
            <DropdownFilter
               defaultValue={tmpFilters?.selectedLocations?.value as Option[]}
               isMulti
               id="selectedLocations"
               onChange={handleDropdownChange}
               options={locationOptions}
               title="Location"
            />
         )}
         <DropdownFilter
            defaultValue={tmpFilters?.selectedManufacturers?.value as Option[]}
            isMulti
            id="selectedManufacturers"
            onChange={handleDropdownChange}
            options={manufacturerOptions}
            title="Manufacturers"
         />
         <DropdownFilter
            defaultValue={tmpFilters?.selectedCategories?.value as Option[]}
            isMulti
            id="selectedCategories"
            onChange={handleDropdownChange}
            options={categoryOptions}
            title="Category"
         />

         <DateRangeFilter
            dateProps={{
               from: {
                  defaultValue: tmpFilters?.DueStartDate?.value as Date,
                  id: 'DueStartDate',
                  placeholder: 'Start date',
               },
               to: {
                  defaultValue: tmpFilters?.DueEndDate?.value as Date,
                  id: 'DueEndDate',
                  placeholder: 'End date',
               },
            }}
            id="DueBackDate"
            onChange={modify}
            title="Due Back Date"
         />

         {/* <DateRangeFilter
                            dateProps={{
                                from: {
                                    defaultValue: tmpFilters?.gpsStartDate?.value as Date,
                                    error: errors?.gpsStartDate,
                                    id: 'gpsStartDate',
                                    placeholder: 'Start date'
                                },
                                to: {
                                    defaultValue: tmpFilters?.gpsEndDate?.value as Date,
                                    error: errors?.gpsEndDate,
                                    id: 'gpsEndDate',
                                    placeholder: 'End date'
                                }
                            }}
                            id='gpsStartDate'
                            onChange={modify}
                            title='Last GPS Scan'
                        />

                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.lastScannedBy?.value as Option[]
                           }
                           id="lastScannedBy"
                           onChange={handleDropdownChange}
                           options={connectionOptions} // TODO: change to scanned by options
                           title="Last Scanned By"
                        /> */}
      </SlidePanel>
   )
}

export default ActionsFilterPanel
