import { useEffect, useState } from 'react'

import { useQuery, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { PAGINATION } from '../../constants/tables'
import { UseItemData, GetItemsRequestOptions } from '../../types'
import { useAPI } from '../'
import { delayedReset } from '../../utils/functions'
import { SortDirection } from '../useFilters/useFilters.type'

export default function (options: GetItemsRequestOptions): UseItemData {
   const request = useAPI()
   const [errorMsg, setErrorMsg] = useState<string | null>(null)

   const { apiMethod = 'get' } = options

   // CUSTOM ACTIONS FOR TABLE - create,edit,delete
   const {
      error: createError,
      isError: isErrorCreate,
      isLoading: isCreating,
      isSuccess: isSuccessCreate,
      mutate: mutateCreate,
      reset: resetCreate,
   } = useMutation(addItemsAPI, {
      onSettled: async () => {
         refresh()
         delayedReset(resetCreate)
      },
      onError: (error: AxiosError<{ Message: string }>) => {
         setErrorMsg(
            error.response.data.Message || 'There was an unknown server error'
         )
      },
   })
   const {
      error: updateError,
      isError: isErrorUpdate,
      isLoading: isUpdating,
      isSuccess: isSuccessUpdate,
      mutate: mutateUpdate,
      reset: resetUpdate,
   } = useMutation(updateItemsAPI, {
      onSettled: async () => {
         refresh()
         delayedReset(resetUpdate)
      },
   })
   const {
      error: deleteError,
      isError: isErrorRemove,
      isLoading: isRemoving,
      isSuccess: isSuccessRemove,
      mutate: mutateRemove,
      reset: resetRemove,
   } = useMutation(removeItemsAPI, {
      onSettled: async () => {
         refresh()
         delayedReset(resetRemove)
      },
   })

   const [pageOptions, setPageOptions] = useState<GetItemsRequestOptions>({
      query: PAGINATION.query,
      skip: PAGINATION.skip,
      take: PAGINATION.take,
      filters: {},
   })

   const [config, setConfig] = useState({
      LIST: '',
      UPDATE: '',
      ADD: '',
      DELETE: '',
      cacheIdentifier: '',
      key: '',
   })

   const cacheIdentifier = config.cacheIdentifier

   async function getItemsFromAPI(
      options: GetItemsRequestOptions
   ): Promise<AxiosResponse> {
      if (config.cacheIdentifier) {
         const { filters, apiMethod: method, ...rest } = options

         const requestParams =
            apiMethod === 'post'
               ? { ...rest, ...filters }
               : { params: { ...rest, ...filters } }

         return request[apiMethod](config.LIST, { ...requestParams })
      }
   }

   async function updateItemsAPI(obj: {}): Promise<AxiosResponse> {
      return request.post(config.UPDATE, obj)
   }

   async function addItemsAPI(obj: object): Promise<AxiosResponse> {
      return request.post(config.ADD, obj)
   }

   async function removeItemsAPI(value: object): Promise<AxiosResponse> {
      if (Number(value)) {
         return request.post(config.DELETE + value)
      }
      return request.post(config.DELETE, value)
   }

   const setPath = (path, cacheIdentifier) => {
      {
         path &&
            setConfig({
               LIST: path.LIST,
               UPDATE: path.UPDATE,
               ADD: path.ADD,
               DELETE: path.DELETE,
               cacheIdentifier,
               key: cacheIdentifier[0].toUpperCase() + cacheIdentifier.slice(1),
            })
      }
   }

   const {
      data,
      isLoading,
      isRefetching,
      refetch: refresh,
   } = useQuery<Promise<AxiosResponse>>([cacheIdentifier], () =>
      getItemsFromAPI(pageOptions)
   )

   const update = (obj: object) => mutateUpdate(obj)

   const add = (obj: object) => mutateCreate(obj)

   const remove = (obj: object) => mutateRemove(obj)

   const get = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: object,
      sortBy?: string,
      sortDirection?: SortDirection
   ) => {
      setPageOptions({ query, skip, take, filters, sortBy, sortDirection })
   }

   useEffect(() => {
      refresh()
   }, [pageOptions])

   useEffect(() => {
      if (createError) {
         return setErrorMsg(createError?.response?.data?.Message)
      }
      if (updateError) {
         return setErrorMsg(updateError?.response.data?.Message)
      }
      if (deleteError) {
         return setErrorMsg(deleteError?.response.data?.Message)
      }
   }, [createError, updateError, deleteError])

   const resetErrorMsg = () => {
      setErrorMsg(null)
   }

   return {
      error: errorMsg,
      resetErrorMsg,
      data: data?.data?.[`${config.key}`] ?? [],
      add,
      get,
      refresh,
      update,
      remove,
      setPath,
      isLoading,
      isRefetching,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
      totalItems: data?.data?.TotalCount,
      setPageOptions,
   }
}
