import React, { memo, useCallback } from 'react'
import * as styles from './cartItemActionButtons.module.scss'

import { IconButton } from '../common'
import { Actions } from '../../constants/tables'
import { ActionButtonProps } from '../common/crud/types'

const CartItemActionButtons = ({
   cell,
   action,
   editAction,
   deleteAction,
}: ActionButtonProps) => {
   const handleEdit = useCallback(
      () => action(Actions.Edit, cell.value),
      [action, cell]
   )

   const handleRemove = useCallback(
      () => action(Actions.Delete, cell.value),
      [action, cell]
   )

   return (
      <div className={styles.buttonsWrapper}>
         {editAction && (
            <IconButton
               id="edit"
               tooltip="Adjust quantity"
               action={handleEdit}
            />
         )}
         {deleteAction && (
            <IconButton
               id="removeFromCart"
               tooltip="Remove from cart"
               action={handleRemove}
            />
         )}
      </div>
   )
}

export default memo(CartItemActionButtons)
