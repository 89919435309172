import { NavItems } from '../../../hooks/useNavigation/useNavigation.type'
import { ROUTES } from '../../routes'

export const historyNavList: NavItems = [
   {
      label: 'Item History',
      route: ROUTES.history,
   },
   {
      label: 'Audit History',
      route: ROUTES.history_audit,
   },
   {
      label: 'Costing History',
      route: ROUTES.history_costing,
   },
]
