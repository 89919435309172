import { NavItems } from '../../../hooks/useNavigation/useNavigation.type'
import { ROUTES } from '../../routes'

export const settingsNavList: NavItems = [
   {
      label: 'System Settings',
      route: ROUTES.settings,
   },
   {
      label: 'Item Record',
      route: ROUTES.settings_itemRecord,
   },
   {
      label: 'Item Mass Edit',
      route: ROUTES.settings_itemMassEdit,
   },
   {
      label: 'Manufacturers',
      route: ROUTES.settings_manufacturers,
   },
   {
      label: 'Categories',
      route: ROUTES.settings_categories,
   },
   {
      label: 'Note Types',
      route: ROUTES.settings_noteTypes,
   },
   {
      label: 'Notifications',
      route: ROUTES.settings_notifications,
   },
]
