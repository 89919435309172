import React, { ChangeEvent, useState, useEffect, useMemo } from 'react'
import { useDebounce } from 'use-debounce'

import * as styles from './search.module.scss'
import Svg from '../svg/svg'

export const Search = ({
   handleQuery,
   pageSized,
   placeHolder,
   setSearchCriteria,
   isPageSearchReset,
   setIsPageSearchReset,
}: SearchProps) => {
   const [inputValue, setInputValue] = useState<string>('')
   const [debouncedValue] = useDebounce(inputValue, 1000)

   useEffect(() => {
      if (isPageSearchReset) {
         handleReset()
         setIsPageSearchReset(false)
      }
   }, [isPageSearchReset])

   useEffect(() => {
      setSearchCriteria(debouncedValue)
      handleQuery(debouncedValue, 0, pageSized)
   }, [debouncedValue])

   const handleInput = (event: ChangeEvent<HTMLInputElement>) =>
      setInputValue(event.target.value)

   const handleReset = () => {
      setInputValue('')
      handleQuery('', 0, pageSized)
   }

   const placeholderText = useMemo(() => `Search ${placeHolder}`, [placeHolder])

   return (
      <div className={styles.searchBar}>
         <input
            type="text"
            placeholder={placeholderText}
            value={inputValue}
            className={styles.search}
            onChange={handleInput}
            onKeyDown={(event) => {
               if (event.keyCode === 13 || event.code === 'Enter') {
                  event.preventDefault()
               }
            }}
         />
         <span className={styles.searchGlass}>
            <Svg id="glass" />
         </span>

         <div
            role="button"
            className={styles.searchReset}
            onClick={(_e) => (inputValue !== '' ? handleReset() : null)}
         >
            &times;
         </div>
      </div>
   )
}
