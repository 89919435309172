import React, { forwardRef, memo, useMemo, useRef } from 'react'
import Select, { createFilter } from 'react-select'

import styles from './nonLabelDropdownStyles'
import { NonLabelDropdownProps } from './types'

// The non label dropdown has the same functionality as as the standard dropdown component but with different styles eg centered text and no label displayed in
// a value container for placeholder this results in some code duplication but we can't keep extending the same standard dropdown component.

const NonLabelDropdown = forwardRef(
   (
      {
         className,
         defaultValue,
         id,
         name,
         onChange,
         options,
      }: NonLabelDropdownProps,
      ref
   ) => {
      const inputRef = useRef()
      const elRef: any = ref || inputRef

      const filterOption = useMemo(
         () =>
            createFilter({
               matchFrom: 'any',
               stringify: (option) => option.label,
            }),
         []
      )

      return (
         <Select
            id={id}
            filterOption={filterOption}
            className={className}
            isClearable={false}
            isMulti={false}
            name={name}
            onChange={onChange}
            options={options}
            ref={elRef}
            styles={styles}
            value={defaultValue}
         />
      )
   }
)

export default memo(NonLabelDropdown)
