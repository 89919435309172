import React, { memo } from 'react'
import classNames from 'classnames'

import { FieldError } from '..'
import * as styles from './fieldWrapper.module.scss'
import { FieldWrapperProps } from './types'

const FieldWrapper = ({
   children,
   className,
   description,
   insetDescription,
   disabled = false,
   error,
   hasFocus,
   id,
   label,
   noMargin = false,
   required,
}: FieldWrapperProps) => (
   <div
      className={classNames(
         styles.fieldWrapper,
         {
            [styles.fieldWrapperError]: !!error,
            [styles.fieldWrapperNoMargin]: noMargin,
            [styles.fieldWrapperNoLabel]: !label,
            [styles.fieldWrapperDisabled]: disabled,
         },
         className
      )}
   >
      <label
         htmlFor={id}
         className={classNames(styles.fieldLabel, {
            [styles.fieldLabelFocussed]: hasFocus,
            [styles.fieldLabelHidden]: !label,
         })}
      >
         {label || id}
         {required && ' *'}
      </label>
      {children}
      {error && <FieldError text={error} />}
      {description && (
         <div
            className={
               !insetDescription
                  ? styles.fieldDescription
                  : styles.fieldDescriptionInset
            }
         >
            {description}
         </div>
      )}
   </div>
)

export default memo(FieldWrapper)
