import React from 'react'
import * as styles from './crudItems.module.scss'

type DropdownWrapperProps = {
   action: ({ value, label }: { value: number | string; label: string }) => void
   children?: JSX.Element
   disabled?: boolean
   bottomMargin?: boolean
}
export const DropdownWrapper = ({
   action,
   children,
   disabled,
   bottomMargin,
}: DropdownWrapperProps) => (
   <>
      <div
         className={`${styles.dropdownWrapper} ${
            bottomMargin ? styles.bottomMargin : ''
         }`}
      >
         <button
            className={styles.dropdownClear}
            onClick={() => action({ value: '', label: null })}
            disabled={disabled}
         >
            <svg
               height="20"
               width="20"
               viewBox="0 0 20 20"
               aria-hidden="true"
               focusable="false"
               fill="hsl(0, 0%, 80%)"
            >
               <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
         </button>
         {children}
      </div>
   </>
)
