// extracted by mini-css-extract-plugin
export var available = "tile-module--available--6a1a5";
export var borrowed = "tile-module--borrowed--f44b3";
export var column = "tile-module--column--adc3f";
export var loaned = "tile-module--loaned--f60f8";
export var locationIcon = "tile-module--locationIcon--a0de6";
export var locationTrackingIcon = "tile-module--locationTrackingIcon--50b39";
export var pending = "tile-module--pending--a6e86";
export var tableHeaderArea = "tile-module--table-header-area--26679";
export var tableTitle = "tile-module--table-title--a677a";
export var tile = "tile-module--tile--82035";
export var tile_content = "tile-module--tile_content--d6a7e";
export var tile_dueBack = "tile-module--tile_dueBack--98d8d";
export var tile_footer = "tile-module--tile_footer--51f61";
export var tile_header = "tile-module--tile_header--e7a26";
export var tile_icons = "tile-module--tile_icons--61dcd";
export var tile_image = "tile-module--tile_image--ff750";
export var tile_info = "tile-module--tile_info--3fbfa";
export var tile_keyValue = "tile-module--tile_keyValue--38c15";
export var tile_location = "tile-module--tile_location--83a6d";
export var tile_locationDue = "tile-module--tile_locationDue--f4c80";
export var tile_locationIcon = "tile-module--tile_locationIcon--f9683";
export var tile_owner = "tile-module--tile_owner--a95b8";
export var tile_status = "tile-module--tile_status--763eb";
export var tile_statusLabel = "tile-module--tile_statusLabel--5dc58";
export var tile_subheader = "tile-module--tile_subheader--d7f5c";
export var tile_top = "tile-module--tile_top--8c003";
export var unavailable = "tile-module--unavailable--a6911";
export var warehouseIcon = "tile-module--warehouseIcon--9a80e";