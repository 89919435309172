import React, { useState, useEffect, useCallback, memo } from 'react'
import { Col, Row } from 'react-grid-system'
import classNames from 'classnames'
import { TABLE } from '../../../constants/tables'
import { LoadingOverlay, Image, KeyValue, Svg } from '../../common'
import { format } from 'date-fns'
import * as styles from './tile.module.scss'
import { ToolTypes } from '../../../types/index'
import { useSettings } from '../../../hooks'
import { formatLocaleDate } from '../../../utils/functions'
import { TileProps } from './types'

const Tile = ({
   totalCount,
   data,
   setBypass,
   getItem,
   isListLoading: isLoading,
   isListRefetching: isRefetching,
}: TileProps) => {
   const { settings } = useSettings()
   const [itemData, setItemData] = useState(data)

   useEffect(() => {
      setItemData(data)
   }, [data])

   const onTileClick = useCallback(
      (item) => {
         setBypass(false)
         getItem(item.Id, item.ToolLoanId, item.KitId)
      },
      [getItem, setBypass]
   )

   const renderTileLocation = useCallback(
      (item) => (
         <>
            <span>
               {item?.DefaultLocation === item?.CurrentLocation ? (
                  <Svg
                     id="warehouse"
                     height="34"
                     width="34"
                     className={styles.warehouseIcon}
                  />
               ) : (
                  <Svg
                     className={styles.locationIcon}
                     id="location"
                     width="16"
                  />
               )}
            </span>
            <span>{item?.CurrentLocation}</span>
         </>
      ),
      []
   )

   return (
      <>
         <div className={styles.tableHeaderArea}>
            {(isLoading || isRefetching) && <LoadingOverlay />}
            <div className={styles.tableTitle}>
               {totalCount} {TABLE.COUNT_LABEL}
            </div>
         </div>

         {itemData?.length > 0 && (
            <Row gutterWidth={25}>
               {itemData.map((item: any, index: number) => {
                  return (
                     <Col key={index} xs={12} xl={6} className={styles.column}>
                        <div
                           className={classNames(styles.tile, {
                              [styles[item.StatusDesc.toLowerCase()]]: true,
                           })}
                           onClick={() => onTileClick(item)}
                        >
                           <div className={styles.tile_content}>
                              <div className={styles.tile_top}>
                                 <h3 className={styles.tile_header}>
                                    {item.Title}
                                    <small className={styles.tile_subheader}>
                                       {item.ModelNumber && item.Manufacturer
                                          ? `${item.Manufacturer} | ${item.ModelNumber}`
                                          : item.Manufacturer}
                                    </small>
                                 </h3>

                                 <div className={styles.tile_status}>
                                    <div className={styles.tile_statusLabel}>
                                       {[2, 4].includes(item.ToolType)
                                          ? `${item.StatusQuantity} `
                                          : ''}
                                       {item.StatusDesc}
                                    </div>

                                    <div className={styles.tile_icons}>
                                       {item.GPS && (
                                          <span title="Location Tracking">
                                             <Svg
                                                id="locations"
                                                width={16}
                                                className={
                                                   styles.locationTrackingIcon
                                                }
                                                overrideViewBox="0 0 16 17"
                                             />
                                          </span>
                                       )}
                                       {item.Notifications > 0 && (
                                          <span title="Tasks">
                                             <Svg id="tasks" width={18} />
                                          </span>
                                       )}
                                       {item.NoteCount > 0 && (
                                          <span title="Notes">
                                             <Svg id="notes" width={15} />
                                          </span>
                                       )}
                                    </div>
                                 </div>
                              </div>

                              <div className={styles.tile_image}>
                                 <Image
                                    width={100}
                                    url={item.PictureURL}
                                    alt={item.title}
                                    defaultImagePadding={0}
                                 />
                              </div>
                              <div className={styles.tile_info}>
                                 <Row gutterWidth={15}>
                                    <Col xs={6} xxl={4}>
                                       <KeyValue
                                          className={styles.tile_keyValue}
                                          label="Category"
                                          value={item.Category}
                                          svgId="cardViewCategory"
                                       />
                                    </Col>
                                    <Col xs={6} xxl={4}>
                                       <KeyValue
                                          className={styles.tile_keyValue}
                                          label="Barcode"
                                          value={item.Barcode}
                                          svgId="cardViewBarcode"
                                       />
                                    </Col>
                                    <Col xs={6} xxl={4}>
                                       <KeyValue
                                          className={styles.tile_keyValue}
                                          label="Serial number"
                                          value={item.SerialNumber}
                                          svgId="cardViewSerialNumber"
                                       />
                                    </Col>
                                    {settings.KitEnabled &&
                                       item.ToolType !== ToolTypes.Kit && (
                                          <Col xs={6} xxl={4}>
                                             <KeyValue
                                                className={styles.tile_keyValue}
                                                label="Assigned to Kit"
                                                value={item.AssignedToKitName}
                                                svgId="cardViewAssignedToKit"
                                             />
                                          </Col>
                                       )}
                                    {settings.CustomText1Enabled && (
                                       <Col xs={6} xxl={4}>
                                          <KeyValue
                                             className={styles.tile_keyValue}
                                             label={settings.CustomText1Label}
                                             value={item.CustomText1Value}
                                             svgId="cardViewText"
                                          />
                                       </Col>
                                    )}

                                    <Col xs={6} xxl={4}>
                                       {item?.GPS && (
                                          <KeyValue
                                             className={styles.tile_keyValue}
                                             label={'Last GPS Scan'}
                                             value={formatLocaleDate(
                                                item.GPS.CreatedOn
                                             )}
                                             svgId="cardViewGps"
                                          />
                                       )}

                                       {item?.Bluetooth && (
                                          <KeyValue
                                             className={styles.tile_keyValue}
                                             label={'Last Bluetooth Ping'}
                                             value={item.CustomText1Value}
                                             svgId="cardViewBluetooth"
                                          />
                                       )}
                                    </Col>
                                 </Row>
                              </div>
                           </div>
                           <div className={styles.tile_footer}>
                              <div className={styles.tile_owner}>
                                 <span>
                                    <Svg id="assigned to" />
                                 </span>
                                 <span>
                                    {item?.AssignedToUser ?? item?.OwnerName}
                                 </span>
                              </div>
                              <div className={styles.tile_locationDue}>
                                 {item.StatusDesc === 'Loaned' && (
                                    <div className={styles.tile_dueBack}>
                                       <span>
                                          <Svg id="return tool" />
                                       </span>
                                       <span title="Date due back">
                                          {`Due ${
                                             item.DueBackDate ===
                                                '2099-12-31T00:00:00' ||
                                             item.DueBackDate === null
                                                ? '-'
                                                : format(
                                                     new Date(item.DueBackDate),
                                                     'MM/dd/yyyy'
                                                  )
                                          }`}
                                       </span>
                                    </div>
                                 )}

                                 <div className={styles.tile_location}>
                                    {settings.EnableLocationTracking &&
                                       item?.CurrentLocation &&
                                       renderTileLocation(item)}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Col>
                  )
               })}
            </Row>
         )}
      </>
   )
}

export default memo(Tile)
