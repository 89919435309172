import React, { memo, MouseEvent, useCallback, useMemo } from 'react'
import classNames from 'classnames'

import * as styles from './styles.module.scss'

const Checkbox = ({
   id,
   label,
   isChecked,
   onClick,
   isDisabled = false,
}: CheckboxProps) => {
   const handleCheckbox = useCallback(
      (e: MouseEvent<HTMLLabelElement>) => {
         e.preventDefault()
         if (!isDisabled) {
            onClick(id, !isChecked)
         }
      },
      [onClick, id, isChecked, isDisabled]
   )

   const classes = useMemo(
      () =>
         classNames({
            [styles.checkbox]: true,
            [styles.disabled]: isDisabled,
         }),
      [isChecked, isDisabled]
   )

   return (
      <label htmlFor={id} className={classes} onClick={handleCheckbox}>
         <input
            type="checkbox"
            id={id}
            checked={isChecked}
            onChange={() => {}}
         />
         <span className={styles.checkmark}></span>
         <span className={styles.label}>{label}</span>
      </label>
   )
}

export default memo(Checkbox)
