import React, { memo } from 'react'
import * as styles from './styles.module.scss'

import { Button, Search } from '..'
import { TableActionsProps } from './types'
import TableActionButton from './tableActionButton/tableActionButton'

const TableActions = ({
   handleSearchQuery,
   pageSized,
   searchPlaceholder,
   setSearchCriteria,
   isPageSearchReset,
   setIsPageSearchReset,
   isColumnsActive,
   onColumnsButtonClick,
   isSortActive,
   onSortButtonClick,
   isFiltersActive,
   onFiltersButtonClick,
   primaryButtonLabel,
   isPrimaryButtonDisabled,
   onPrimaryButtonClick,
   onCancelButtonClick,
}: TableActionsProps) => (
   <div className={styles.tableActions}>
      <div className={styles.tableActionsSearch}>
         <Search
            handleQuery={handleSearchQuery}
            pageSized={pageSized}
            placeHolder={searchPlaceholder}
            setSearchCriteria={setSearchCriteria}
            isPageSearchReset={isPageSearchReset}
            setIsPageSearchReset={setIsPageSearchReset}
         />
      </div>
      {onColumnsButtonClick && (
         <TableActionButton
            variant="columns"
            isActive={isColumnsActive}
            onClick={onColumnsButtonClick}
         />
      )}
      {onSortButtonClick && (
         <TableActionButton
            variant="sort"
            isActive={isSortActive}
            onClick={onSortButtonClick}
         />
      )}
      {onFiltersButtonClick && (
         <TableActionButton
            variant="filters"
            isActive={isFiltersActive}
            onClick={onFiltersButtonClick}
         />
      )}
      {onPrimaryButtonClick && (
         <Button
            className={styles.tableActionsButton}
            type="button"
            variant="primary"
            onClick={onPrimaryButtonClick}
            disabled={isPrimaryButtonDisabled}
            minWidth="158px"
         >
            {primaryButtonLabel}
         </Button>
      )}
      {onCancelButtonClick && (
         <Button
            className={styles.tableActionsButton}
            type="button"
            variant="tertiary"
            onClick={onCancelButtonClick}
            minWidth="158px"
         >
            Cancel
         </Button>
      )}
   </div>
)

export default memo(TableActions)
