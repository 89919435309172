import React, { useEffect } from 'react'
import { Col, Row } from 'react-grid-system'
import { TableWrapper } from '../table/TableWrapper'
import { Button, Image, LoadingOverlay } from '..'
import { useSettings, useItems, useTotalCount } from '../../../hooks'
import { PATH, formatPrice } from '../../../constants/global'
import { Item, ToolTypes } from '../../../types'
import { PAGINATION } from '../../../constants/tables'

import { Props } from './itemSelector.type'

const ItemSelector: React.FC<Props> = ({
   selectedItems = [],
   isAllItemsSelected,
   handleAddBackToDefaultView,
   onSelect,
   onSelectAll,
   searchCriteria,
   itemFilters,
   handleCancel,
   getTotalItemsPerPage,
}) => {
   const { skip, take } = PAGINATION
   const { data: items, isLoading, list, setPath, isRefetching } = useItems()
   const { data: totalCount, setCountPath, getCount } = useTotalCount({})
   const { settings, isLoading: settingsLoading } = useSettings()

   const {
      KitEnabled,
      QuantityEnabled,
      EnableLocationTracking,
      CustomText1Enabled,
      CustomText1Label,
      CustomText2Enabled,
      CustomText2Label,
   } = settings

   useEffect(() => {
      setPath(PATH.ITEMS, 'audit-items')
      setCountPath(PATH.ITEMS, 'audit-items-count')
      list({
         skip,
         take,
      })
   }, [])

   useEffect(() => {
      getTotalItemsPerPage(items)
   }, [items])

   useEffect(() => {
      handleGetItems(searchCriteria, skip, take, itemFilters)
   }, [searchCriteria, itemFilters])

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const filtersObject = filters || itemFilters
      const criteria = {
         skip,
         take,
         query,
         ...filtersObject,
      }

      list(criteria)
      getCount(criteria)
   }

   const columns = [
      {
         Header: () => (
            <input
               type="checkbox"
               onChange={() => onSelectAll(items)}
               name={'select-all-items'}
               id={'select-all-items'}
               defaultChecked={isAllItemsSelected}
            />
         ),
         accessor: 'Id',
         maxWidth: 30,
         minWidth: 30,
         align: 'center',
         Cell: ({ row }) => {
            const rowItem = row?.original as Item
            const inputId = `input-${rowItem?.localUniqueId}`

            return (
               <input
                  type="checkbox"
                  onChange={() => onSelect(rowItem)}
                  name={inputId}
                  id={inputId}
                  defaultChecked={
                     !!selectedItems?.find(
                        (item) => item.localUniqueId === rowItem.localUniqueId
                     )
                  }
               />
            )
         },
      },
      {
         Header: 'Pic',
         accessor: 'PictureURL',
         id: 'PictureURL',
         allowBlank: true,
         maxWidth: 45,
         minWidth: 45,
         align: 'center',
         Cell: (cell: { row: { original: { PictureURL: string } } }) => (
            <Image
               url={cell.row.original.PictureURL}
               alt={cell.row.original.PictureURL}
               defaultImagePadding={3}
               width={25}
            />
         ),
      },
      {
         Header: 'Item Name',
         accessor: 'Title',
         minWidth: 250,
      },
      {
         Header: 'Status',
         accessor: 'StatusDesc',
         id: 'StatusDesc',
         minWidth: 150,
      },
      {
         Header: 'Loaned/Pending To',
         accessor: (row) => {
            if (row.AssignedToUser === null) {
               return ''
            }
            return row.AssignedToUser
         },
         id: 'AssignedToUser',
         minWidth: 250,
      },
      ...(!!EnableLocationTracking
         ? [
              {
                 Header: 'Current/Pending Location',
                 accessor: 'CurrentLocation',
                 id: 'CurrentLocation',
                 minWidth: 250,
              },
           ]
         : []),
      ...(!!KitEnabled
         ? [
              {
                 Header: 'Assigned to Kit',
                 accessor: 'AssignedToKitName',
                 id: 'AssignedToKitName',
                 minWidth: 250,
              },
           ]
         : []),
      ...(!!QuantityEnabled
         ? [
              {
                 Header: 'Total Quantity',
                 id: 'Quantity',
                 accessor: 'Quantity',
                 minWidth: 250,
              },
              {
                 Header: 'Status Quantity',
                 accessor: 'StatusQuantity',
                 id: 'StatusQuantity',
                 minWidth: 250,
              },
           ]
         : []),
      {
         Header: 'Manufacturer',
         accessor: 'Manufacturer',
         id: 'Manufacturer',
         minWidth: 250,
      },
      {
         Header: 'Model No.',
         accessor: 'ModelNumber',
         id: 'ModelNumber',
         minWidth: 250,
      },
      {
         Header: 'Serial No.',
         accessor: 'SerialNumber',
         id: 'SerialNumber',
         minWidth: 250,
      },
      {
         Header: 'Barcode',
         accessor: 'Barcode',
         id: 'Barcode',
      },
      {
         Header: 'Category',
         accessor: 'Category',
         id: 'Category',
      },
      {
         Header: 'Type',
         accessor: 'ToolType',
         id: 'ToolType',
         minWidth: 150,
         Cell: ({ row }) => ToolTypes[row.original.ToolType],
      },
      ...(!!EnableLocationTracking
         ? [
              {
                 Header: 'Return Warehouse',
                 accessor: 'DefaultLocation',
                 id: 'DefaultLocation',
                 minWidth: 250,
              },
           ]
         : []),
      {
         Header: 'Purchase Price',
         accessor: (row) => formatPrice(row.PurchasePrice || 0),
         id: 'PurchasePrice',
         minWidth: 250,
      },
      ...(!!CustomText1Enabled
         ? [
              {
                 Header: CustomText1Label,
                 accessor: 'CustomText1Value',
                 id: 'CustomText1Value',
                 minWidth: 150,
              },
           ]
         : []),
      ...(!!CustomText2Enabled
         ? [
              {
                 Header: CustomText2Label,
                 accessor: 'CustomText2Value',
                 id: 'CustomText2Value',
                 minWidth: 150,
              },
           ]
         : []),
      {
         Header: 'Item ID',
         accessor: 'ItemId',
         id: 'ItemId',
      },
   ]

   return (
      <>
         {(isRefetching || isLoading) && <LoadingOverlay />}
         <TableWrapper
            columns={columns}
            data={items}
            getItems={handleGetItems}
            totalCount={totalCount}
            extraClassName=""
            isLoading={isLoading || settingsLoading}
            isPageReset={false}
            searchCriteria={searchCriteria}
            setItemId={() => {}}
            setPageSized={() => null}
         />
         <hr />
         <Row gutterWidth={20}>
            <Col xs={12} lg={6}>
               <Button minWidth="100%" onClick={handleAddBackToDefaultView}>
                  Add To Audit
               </Button>
            </Col>
            <Col xs={12} lg={6}>
               <Button
                  minWidth="100%"
                  onClick={handleCancel}
                  variant={'tertiary'}
               >
                  Cancel
               </Button>
            </Col>
         </Row>
      </>
   )
}

export default ItemSelector
