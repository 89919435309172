import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import SlidePanel from '../slidePanel/slidePanel'
import { Option } from '../form/fields/dropdown/types'
import { SortDirection } from '../../../hooks/useFilters/useFilters.type'
import NonLabelDropdown from '../form/fields/nonLabelDropdown/nonLabelDropdown'

import { SortPanelProps } from './types'
import * as styles from './styles.module.scss'

const SortPanel = ({
   isSortPanelOpen,
   onClose,
   handleSortReset,
   handleApplySortOptions,
   columnOptions = [],
   sortRef,
   activeSortHeader,
}: SortPanelProps) => {
   const [selectedSortDirection, setSelectedSortDirection] =
      useState<Option>(null)
   const [selectedColumnOption, setSelectedColumnOption] =
      useState<Option>(null)

   const sortDirectionOptions = useMemo(
      () => [
         { label: 'Ascending', value: SortDirection.ASCENDING },
         { label: 'Descending', value: SortDirection.DESCENDING },
      ],
      []
   )

   useEffect(() => {
      if (activeSortHeader) {
         const selectedOption = columnOptions?.find(
            (option) =>
               option.value.toLowerCase() ===
               activeSortHeader.sortBy.toLowerCase()
         )
         setSelectedColumnOption(selectedOption)

         const selectedDirection = sortDirectionOptions?.find(
            (option) => option.value === activeSortHeader.sortDirection
         )
         setSelectedSortDirection(selectedDirection)
      }
   }, [activeSortHeader, columnOptions])

   const handleColumnOptionChange = useCallback(
      (option: Option) => setSelectedColumnOption(option),
      []
   )

   const handleSortDirectionChange = useCallback(
      (option: Option) => setSelectedSortDirection(option),
      []
   )

   const handleSave = useCallback(
      () =>
         handleApplySortOptions(
            selectedColumnOption.value,
            selectedSortDirection.value
         ),
      [selectedColumnOption, selectedSortDirection]
   )

   return (
      <SlidePanel
         variant="Sort"
         isOpen={isSortPanelOpen}
         onClose={onClose}
         onReset={handleSortReset}
         onSave={handleSave}
      >
         <form ref={sortRef} className={styles.form}>
            <p>
               Select the column you wish to sort by, then select Ascending or
               Descending sort. For more sort options, export to Excel.
            </p>
            <p className={styles.sortByText}>Sort By</p>
            <NonLabelDropdown
               className={styles.columnOptionField}
               id="columns"
               name="selectColumn"
               onChange={handleColumnOptionChange}
               options={columnOptions}
               defaultValue={selectedColumnOption}
            />
            <NonLabelDropdown
               id="sortDirection"
               name="sortDirection"
               onChange={handleSortDirectionChange}
               options={sortDirectionOptions}
               defaultValue={selectedSortDirection}
            />
         </form>
      </SlidePanel>
   )
}

export default memo(SortPanel)
