import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import { Button, Svg } from '../..'
import * as styles from './styles.module.scss'
import { capitalize } from '../../../../utils/functions'
import { BiColumns } from 'react-icons/bi'

const TableActionButton = ({
   variant,
   isActive = false,
   onClick,
}: TableActionButtonProps) => {
   const buttonClassName = useMemo(
      () =>
         classNames(styles.actionButton, {
            [styles.actionButtonActive]: isActive,
         }),
      [isActive]
   )

   const iconClassName = useMemo(
      () =>
         classNames(styles.actionButtonIcon, {
            [styles.actionButtonIconActive]: isActive,
         }),
      [isActive]
   )

   const icon = useMemo(
      () => (variant === 'columns' ? <BiColumns /> : <Svg id={variant} />),
      [variant]
   )

   const label = useMemo(() => capitalize(variant), [variant])

   return (
      <Button
         type="button"
         className={buttonClassName}
         variant="plain"
         preserveText
         onClick={onClick}
         minWidth="auto"
      >
         <span className={iconClassName}>{icon}</span>
         <span>{label}</span>
      </Button>
   )
}

export default memo(TableActionButton)
