// @ts-ignore
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { PAGINATION } from '../../../constants/tables'
import { populateArray } from '../../../utils/functions'
import { PaginationTotal, PaginationSize, PaginationPages } from './'
import * as styles from './pagination.module.scss'

export default function Pagination({
   setPageSize,
   setPageSized,
   pageSize,
   totalCount,
   getItems,
   isPageReset,
   setIsPageReset,
   searchCriteria,
   toolStatus = 0,
   pageDataLength,
}) {
   const [previousAllowed, setPreviousAllowed] = useState(false)
   const [nextAllowed, setNextAllowed] = useState(false)
   let [currentPage, setCurrentPage] = useState(1)
   const [pagesAvailable, setPagesAvailable] = useState(PAGINATION.take)
   const [pageButtonArray, setPageButtonArray] = useState([])
   const [pageGroup, setPageGroup] = useState(0)
   const [isPagination, setIsPagination] = useState(true)
   const [count, setCount] = useState(0)

   const pageGroupCalc = (page: number) =>
      Math.ceil(page / PAGINATION.ellipsisSkip) * PAGINATION.ellipsisSkip -
      PAGINATION.ellipsisSkip +
      1

   const handleSetCurrentPage = (next: boolean) => {
      next
         ? setCurrentPage((currentPage += 1))
         : setCurrentPage((currentPage -= 1))
      getItems(searchCriteria, pageSize * (currentPage - 1), pageSize)
      checkPageAccess()
   }

   const checkPageAccess = () => {
      currentPage <= 1 ? setPreviousAllowed(false) : setPreviousAllowed(true)
      currentPage >= pagesAvailable
         ? setNextAllowed(false)
         : setNextAllowed(true)
   }

   const handleNextPage = () => {
      if (nextAllowed) {
         handleSetCurrentPage(true)
      }
   }

   const handlePreviousPage = (skip?, take?) => {
      if (previousAllowed) {
         handleSetCurrentPage(false)
      }
   }

   const handleGotoPage = (page: number) => {
      setCurrentPage(page)
      getItems(searchCriteria, pageSize * (page - 1), pageSize)
      checkPageAccess()
   }

   const handleButtonArray = (
      activePage: number,
      size = PAGINATION.pageSized
   ) => {
      const activeSize = Math.ceil(size / PAGINATION.pageSized)
      const pageGroupNumber = pageGroupCalc(activePage)
      const pageGroupMaximum =
         pageGroupCalc(pageGroupNumber) + (PAGINATION.ellipsisSkip - 1)
      const pagesize = activeSize > pageSize ? activeSize : pageSize
      const pages = totalCount / pagesize + 1
      if (pages > activeSize || pageSize) setIsPagination(true)
      setPageButtonArray(
         populateArray(pageGroupNumber, pageGroupMaximum, 1, pages)
      )
      setPageGroup(pageGroupNumber)
   }

   const handleSetPageSize = (el: number) => {
      setPageSize(el) // this belongs to react-table
      setPageSized(el) // this handles page size for search
      getItems(searchCriteria, 0, el)
      setCurrentPage(1)
      handleButtonArray(1, el)
   }

   const handlePagesAvailable = (el?: undefined) => {
      const maxPages = Math.ceil(totalCount / (el || pageSize))
      setPagesAvailable(maxPages)
      return maxPages
   }

   const isNextEllipsis = () => pagesAvailable - pageGroup > 10

   const isPreviousEllipsis = () => pageGroup > 1

   useEffect(() => {
      setCurrentPage(1)
   }, [toolStatus])

   useEffect(() => {
      handlePagesAvailable()
   }, [pageSize])

   useEffect(() => {
      handleButtonArray(currentPage)
   }, [currentPage])

   useEffect(() => {
      checkPageAccess()
      if (pagesAvailable <= 0) {
         handleSetPageSize(10)
      }
   })

   useEffect(() => {
      // put in place for #15307 17/10/22 if bugs occur on any pages remove or alter for actions page

      if (pageDataLength === 0 && currentPage > 1) {
         setCurrentPage(currentPage - 1)
         getItems(searchCriteria, pageSize * (currentPage - 2), pageSize)
      }
   }, [pageDataLength])

   useEffect(() => {
      checkPageAccess()
   }, [nextAllowed, previousAllowed])

   useEffect(() => {
      handleButtonArray(currentPage + 1)
   }, [])

   useEffect(() => {
      handlePagesAvailable()
      handleButtonArray(currentPage, PAGINATION.pageSized)
   }, [totalCount])

   useEffect(() => {
      totalCount < 10 ? setIsPagination(false) : setIsPagination(true)
   }, [totalCount])

   useEffect(() => {
      if (isPageReset) {
         setCurrentPage(1)
         if (setIsPageReset && currentPage === 1) {
            setIsPageReset(false)
         }
      }
   }, [isPageReset, currentPage])

   return (
      isPagination && (
         <div className={styles.paginationContainer}>
            <PaginationSize
               pageSize={pageSize}
               totalCount={totalCount}
               handleSetPageSize={handleSetPageSize}
               count={count}
               setCount={setCount}
            />
            <PaginationPages
               currentPage={currentPage}
               nextAllowed={nextAllowed}
               isPreviousEllipsis={isPreviousEllipsis}
               isNextEllipsis={isNextEllipsis}
               previousAllowed={previousAllowed}
               pageGroup={pageGroup}
               pageButtonArray={pageButtonArray}
               handleGotoPage={handleGotoPage}
               handlePreviousPage={handlePreviousPage}
               handleNextPage={handleNextPage}
               pagesAvailable={pagesAvailable}
               handleButtonArray={handleButtonArray}
               totalCount={totalCount}
               count={count}
            />
            <PaginationTotal
               currentPage={currentPage}
               itemsPerPage={pageSize}
               pagesAvailable={pagesAvailable}
               totalCount={totalCount}
            />
         </div>
      )
   )
}
