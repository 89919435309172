import React, { useMemo } from 'react'

import * as styles from './importItemsModal.module.scss'
import {
   ImportItemModalViews,
   ImportStatusViewProps,
} from './importItemsModal.type'
import { useProfile } from '../../../hooks'
import { StaticImage } from 'gatsby-plugin-image'
import { HelpButton } from './actions'
import Svg from '../svg/svg'
import { ProgressBar } from '../progressBar'
import { ProgressBarVariant } from '../progressBar/types'

const ImportStatusView = ({
   currentView,
   uploadPercentage,
   addImagesWithAI,
   importError,
   importFileErrors,
   totalItemsImported,
   totalImagesImported,
}: ImportStatusViewProps) => {
   const { profile } = useProfile()

   const heading = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.IMPORTING:
            return 'Importing Your Items'
         case ImportItemModalViews.SUCCESS:
            return `${profile?.Firstname}, Your Items Are Imported!`
         case ImportItemModalViews.ERROR:
         default:
            return 'Sorry! Import Could Not Complete.'
      }
   }, [currentView])

   const description = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.IMPORTING:
            return 'We have your Item import file and are working behind the scenes to get everything ready for you.'
         case ImportItemModalViews.SUCCESS:
            return 'ShareMyToolbox has finished your Item import. Time to take a break and reward yourself for a job well done.'
         case ImportItemModalViews.ERROR:
         default:
            return 'Some errors were found during the import process. Please correct them and try again. Contact us if you need help, real humans are standing by!'
      }
   }, [currentView])

   const content = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.SUCCESS:
            return (
               <>
                  <StaticImage
                     src={'../../../assets/images/importItems/thumbs-up.png'}
                     formats={['png']}
                     alt={'Import successful'}
                  />
                  <div className={styles.importResults}>
                     <div>
                        <Svg
                           id="items"
                           width={30}
                           height={28}
                           stroke="#FED600"
                           overrideViewBox="0 0 20 20"
                           className={styles.itemsIcon}
                        />
                        <strong>
                           {totalItemsImported} Item
                           {Number(totalItemsImported) === 1 ? '' : 's'} Added
                        </strong>
                     </div>
                     {addImagesWithAI && (
                        <div>
                           <Svg
                              id="imageMatch"
                              fill="#FED600"
                              width={30}
                              height={26}
                              overrideViewBox="0 0 26 28"
                              className={styles.imagesIcon}
                           />
                           <strong>
                              {totalImagesImported} Image
                              {Number(totalImagesImported) === 1 ? '' : 's'} Added
                           </strong>
                        </div>
                     )}
                  </div>
               </>
            )
         case ImportItemModalViews.ERROR:
            return (
               <>
                  <p>{importError}</p>
                  {importFileErrors?.length ? (
                     importFileErrors.map((item) => (
                        <p key={item.split(' ').join('-')}>{item}</p>
                     ))
                  ) : (
                     <></>
                  )}
               </>
            )
         default:
            return <></>
      }
   }, [currentView, importFileErrors, totalItemsImported])

   const progressBarTitle = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.IMPORTING:
            if (addImagesWithAI) {
               if (uploadPercentage <= 16) {
                  return 'Importing Items...'
               } else if (uploadPercentage > 16 && uploadPercentage <= 32) {
                  return 'Analyzing data to find images...'
               } else if (uploadPercentage > 32 && uploadPercentage <= 48) {
                  return 'Toolbox AI searching for images...'
               } else if (uploadPercentage > 48 && uploadPercentage <= 64) {
                  return 'Toolbox AI adding images to your database...'
               } else if (uploadPercentage > 64 && uploadPercentage <= 80) {
                  return 'Running final data cleanup and polish...'
               } else {
                  return 'Almost there...'
               }
            } else {
               if (uploadPercentage <= 50) {
                  return 'Importing Items...'
               } else if (uploadPercentage > 50 && uploadPercentage <= 75) {
                  return 'Running final data cleanup and polish...'
               } else {
                  return 'Almost there...'
               }
            }
         case ImportItemModalViews.SUCCESS:
            return 'Import Complete'
         case ImportItemModalViews.ERROR:
         default:
            return 'Import Failed'
      }
   }, [currentView, uploadPercentage])

   const progressBarVariant: ProgressBarVariant = useMemo(
      () => (currentView === ImportItemModalViews.ERROR ? 'danger' : 'success'),
      [currentView]
   )

   // Reintroduce once close and continue implementation is complete
   // const bottomSection = useMemo(
   //    () =>
   //       currentView === ImportItemModalViews.IMPORTING ? (
   //          <div className={styles.closeWindowInfo}>
   //             <StaticImage
   //                className={styles.greenTriangle}
   //                src={'../../../assets/images/importItems/green-triangle.png'}
   //                formats={['png']}
   //                alt={''}
   //             />
   //             <p>
   //                If you wish, you can safely close this window and we'll send
   //                you an Info Notification on your Dashboard when the import is
   //                complete.{' '}
   //             </p>
   //          </div>
   //       ) : (
   //          <></>
   //       ),
   //    [currentView]
   // )

   return (
      <div className={styles.leftColumn}>
         <h3 className={styles.heading}>{heading}</h3>
         <p className={styles.description}>{description}</p>
         <div
            className={
               currentView === ImportItemModalViews.ERROR
                  ? styles.errorContent
                  : styles.content
            }
         >
            {currentView === ImportItemModalViews.ERROR && <HelpButton />}
            {content}
         </div>
         <ProgressBar
            textAbove={progressBarTitle}
            percentage={uploadPercentage}
            variant={progressBarVariant}
         />
      </div>
   )
}

export default ImportStatusView
