import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'
import { APIError } from '../types'
import { getAPIErrorMessage } from '../../utils/functions'

export default (): UseAdjustQuantity => {
   const request = useAPI()
   const [errorMessage, setErrorMessage] = useState<string | null>(null)

   const {
      data,
      isLoading: isReasonsLoading,
      isError: isReasonsError,
      isSuccess: isReasonsSuccess,
   } = useQuery('adjust-reason-options', getReasonOptionsFromAPI, {
      onError: (error: APIError) => {
         const msg = getAPIErrorMessage(error)
         setErrorMessage(msg)
      },
   })

   async function getReasonOptionsFromAPI(): Promise<
      AxiosResponse<AdjustReasonOption[]>
   > {
      return await request.get(PATH.ITEMS.ADJUST_REASON_OPTIONS)
   }

   const { mutate, isLoading, isSuccess, isError } = useMutation(
      'adjust-quantity',
      adjustQuantityInAPI,
      {
         onError: (error: APIError) => {
            const msg = getAPIErrorMessage(error)
            setErrorMessage(msg)
         },
      }
   )

   async function adjustQuantityInAPI(
      payload: AdjustQuantityPayload
   ): Promise<AxiosResponse<AdjustQuantityResponse>> {
      return await request.post(PATH.ITEMS.ADJUST_QUANTITY, payload)
   }

   const adjustQuantity = (payload: AdjustQuantityPayload) => mutate(payload)

   useEffect(() => {
      if ((!isError && !isReasonsError) || isLoading || isReasonsLoading) {
         setErrorMessage(null)
      }
   }, [isError, isLoading, isReasonsError, isReasonsLoading])

   return {
      adjustReasons: data?.data ?? [],
      adjustQuantity,
      isLoading: isLoading || isReasonsLoading,
      isSuccess,
      isError,
      error: errorMessage,
      isReasonsSuccess,
      isReasonsError,
   }
}
