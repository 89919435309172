import React, { memo } from 'react'

import { CartButtonProps } from './actionsWorkflow.type'
import { Svg, Button } from '../common'

const CartButton = ({ onClick, text, iconId, disabled }: CartButtonProps) => (
   <Button
      variant="plain"
      preserveText
      onClick={onClick}
      minWidth="auto"
      disabled={disabled}
   >
      <Svg
         id={iconId}
         width={iconId === 'addToCart' ? 19 : 14}
         height={iconId === 'addToCart' ? 18 : 14}
      />
      {text}
   </Button>
)

export default memo(CartButton)
