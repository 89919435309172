import React from 'react'
import classNames from 'classnames'

import { FieldWrapper } from '..'

import * as styles from './select.module.scss'
import { SelectProps } from './types'

const Select = ({
   description,
   disabled,
   error,
   hideDefaultOption = false,
   id,
   inputProps,
   isControlled = false,
   label,
   noMargin = false,
   onChange,
   options,
   required = false,
   withWrappers = true,
   value,
}: SelectProps) => {
   const renderElement = () => (
      <select
         {...(inputProps || {})}
         className={classNames(styles.select, {
            [styles.selectNolabel]: !label,
         })}
         onChange={onChange ?? null}
         {...(!isControlled && { defaultValue: value || '' })}
         {...(isControlled && { value: value || '' })}
         required={required}
         disabled={disabled}
         aria-invalid={!!error}
         id={id}
         name={id}
      >
         {!hideDefaultOption && <option value="">- Choose -</option>}
         {options.map((option, i) => (
            <option key={option.key} value={option.key}>
               {option.value}
            </option>
         ))}
      </select>
   )

   if (!withWrappers) {
      return renderElement()
   }

   return (
      <FieldWrapper
         description={description}
         disabled={disabled}
         error={error}
         hasFocus={true}
         id={id}
         label={label}
         required={required}
         noMargin={noMargin}
      >
         {renderElement()}
      </FieldWrapper>
   )
}

export default Select
