import React, { memo, useCallback, useMemo } from 'react'
import classNames from 'classnames'

const Toggle = ({
   size,
   label,
   isChecked,
   onToggle,
   id,
   disabled = false,
}: ToggleProps) => {
   const handleToggle = useCallback(() => {
      if (!disabled) {
         onToggle(id, !isChecked)
      }
   }, [onToggle, id, isChecked, disabled])

   const classes = useMemo(
      () =>
         classNames({
            toggle: true,
            [`toggle-${size}`]: true,
            isChecked: isChecked,
            disabled: disabled,
         }),
      [size, isChecked, disabled]
   )

   return (
      <label className={classes} htmlFor={id} onClick={handleToggle}>
         <span className="toggle-text">&nbsp;{label}</span>
      </label>
   )
}

export default memo(Toggle)
