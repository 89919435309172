import React, { memo } from 'react'
import { SlidePanel } from '..'
import { ColumnReorderPanelProps } from './types'
import { permissions } from './constants'
import ItemReorder from '../itemReorder/itemReorder'

const ColumnReorderPanel = ({
   isColumnSettingsOpen,
   setIsColumnSettingsOpen,
   handleResetColumns,
   handleSaveColumnReorder,
   handleReorderColumns,
   defaultColumnItems,
   currentColumnItems,
   tableOptions,
}: ColumnReorderPanelProps) => (
   <SlidePanel
      variant="Table Columns"
      isOpen={isColumnSettingsOpen}
      onClose={() => setIsColumnSettingsOpen(false)}
      onReset={handleResetColumns}
      onSave={handleSaveColumnReorder}
   >
      <p>
         Add or remove columns. To change the column order, drag and drop a
         field.
      </p>
      <ItemReorder
         defaultItems={defaultColumnItems}
         items={tableOptions?.length ? currentColumnItems : defaultColumnItems}
         onChange={handleReorderColumns}
         permissions={permissions ?? {}}
      />
   </SlidePanel>
)

export default memo(ColumnReorderPanel)
