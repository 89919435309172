import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseActionItems } from './types'

export default function (): UseActionItems {
   const request = useAPI()
   const [toolSearchOptions, setToolSearchOptions] = useState(null)

   const {
      data,
      refetch,
      isLoading: actionItemsIsLoading,
      isRefetching: actionItemsIsRefetching,
   } = useQuery(
      'action-items',
      (): Promise<AxiosResponse> =>
         getActionItems({ ...toolSearchOptions, isForMultiselectFilter: true }),
      { enabled: !!toolSearchOptions }
   )

   async function getActionItems(options: {}) {
      try {
         return await request.post(PATH.ITEMS.LIST, options)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   const {
      data: actionItemsCountData,
      refetch: refetchCount,
      isLoading: actionItemsCountIsLoading,
      isRefetching: actionItemsCountIsRefetching,
   } = useQuery(
      'action-items-count',
      (): Promise<AxiosResponse> =>
         getActionItemsCount({
            ...toolSearchOptions,
            isForMultiselectFilter: true,
         }),
      { enabled: !!toolSearchOptions }
   )

   async function getActionItemsCount(options: {}) {
      try {
         return await request.post(PATH.ITEMS.COUNT, options)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   useEffect(() => {
      if (toolSearchOptions) {
         refetch()
         refetchCount()
      }
   }, [toolSearchOptions])

   return {
      actionItems: data?.data || [],
      actionItemsIsLoading,
      actionItemsIsRefetching,
      setToolSearchOptions,
      actionItemsCount: actionItemsCountData?.data?.Count,
   }
}
