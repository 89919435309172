import { PATH } from '../../../constants/global'
import { TABLE, CRUD } from '../../../constants/tables'

export const CRUD_TABLE = {
   downloadPath: PATH.NOTES.DOWNLOAD,
   extraClassName: 'table-actions',
   params: '',
   modalTitle: ' Note',
   placeholder: 'Notes',
   addButtonText: 'Add Note',
   modalAddTitle: 'Title',
   key: 'ToolNotes',
   defaultActiveValue: {
      id: '',
      title: '',
      sendNotification: false,
      DueDateReminder: true,
   },
   defaultActionState: 0,
   objectKey: 'title',
   objectKeyTitleCase: 'Title',
   objectKeyNotificationCase: 'SendNotification',
   primaryActionText: CRUD.primaryActionText,
   secondaryActionText: CRUD.secondaryActionText,
   primaryDeleteText: CRUD.primaryDeleteText,
   crudPaths: PATH.NOTES,
   tableTitle: TABLE.TITLE.NOTES,
   header: 'Title',
   toggleTitle: 'Creation Notification',

   downloadRequest: {
      skip: 0,
      take: 0,
   },
}

export const defaultNote = {
   Id: '',
   ToolId: null,
   NoteTypeId: null,
   NoteText: '',
   DueDate: null,
   Completed: false,
   CompletedDate: '',
   Odometer: '',
   Hours: '',
   CompletedCost: '',
   Attachments: [],
   ToolLoanId: '',
}

export const defaultItem = {
   ID: '',
   Title: '',
   Barcode: '',
   SerialNumber: '',
   CustomText1Value: '',
   ToolLoanId: '',
}

export const defaultNoteType = {
   label: '',
   value: '',
}

export const requiredErrorMsg = 'This field is required'
