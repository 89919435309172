import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import { GrClose } from 'react-icons/gr'

import { SlidePanelProps } from './types'
import * as styles from './slidePanel.module.scss'
import { Col, Row } from 'react-grid-system'
import Button from '../button/button'
import Svg from '../svg/svg'
import { BiColumns } from 'react-icons/bi'

const SlidePanel = ({
   children,
   isOpen,
   onClose,
   variant,
   onReset,
   onSave,
}: SlidePanelProps) => {
   const panelClassName = useMemo(
      () =>
         classNames(styles.panel, {
            [styles.panelOpen]: isOpen,
         }),
      [isOpen]
   )

   const resetButtonClassName = useMemo(
      () => classNames(styles.actionButton, styles.actionButtonReset),
      []
   )

   const closeTitle = useMemo(() => `Close ${variant}`, [variant])

   const icon = useMemo(() => {
      switch (variant) {
         case 'Filters':
            return (
               <Svg
                  id="filters"
                  overrideViewBox="0,0,8,8"
                  height="40"
                  width="40"
               />
            )
         case 'Table Columns':
            const biColumnsStyle = {
               marginRight: '0.5rem',
               marginTop: '-0.5rem',
            }
            return <BiColumns style={biColumnsStyle} />
         case 'Sort':
         default:
            return <></>
      }
   }, [variant])

   return (
      <div className={panelClassName}>
         <button title={closeTitle} onClick={onClose} className={styles.close}>
            <GrClose />
         </button>
         <section className={styles.content}>
            <div className={styles.titleWrapper}>
               <div className={styles.titleIcon}>{icon}</div>
               <h2 className={styles.title}>{variant}</h2>
            </div>
            <div className={styles.wrapper}>
               <div className={styles.header}>
                  <Row>
                     <Col xs={6}>
                        <Button
                           className={styles.actionButton}
                           onClick={onSave}
                           minWidth="auto"
                        >
                           Apply
                        </Button>
                     </Col>
                     <Col xs={6}>
                        <Button
                           className={resetButtonClassName}
                           onClick={onReset}
                           minWidth="auto"
                           variant="plain"
                           preserveText
                        >
                           Reset
                        </Button>
                     </Col>
                  </Row>
               </div>

               <div>{children}</div>
            </div>
         </section>
      </div>
   )
}

export default memo(SlidePanel)
