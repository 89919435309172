import { StylesConfig } from 'react-select'

const styles: StylesConfig = {
   control: (defaultStyles, state) => ({
      ...defaultStyles,
      backgroundColor: '#FFFFFF',
      borderColor: `${state.isFocused ? '#ffca05' : '#96999b'} !important`,
      borderRadius: 5,
      boxShadow: '0 !important',
      fontSize: '0.9rem',
      minHeight: 44,
      transition: 'none',
   }),
   clearIndicator: (defaultStyles) => ({
      ...defaultStyles,
      cursor: 'pointer',
      transition: 'none',
   }),
   dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      color: '#96999b',
      cursor: 'pointer',
      transition: 'none',
   }),
   indicatorsContainer: (defaultStyles) => ({
      ...defaultStyles,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      color: '#96999b',
   }),
   indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#96999b',
      margin: 0,
   }),
   input: (defaultStyles) => ({
      ...defaultStyles,
      display: 'flex',
      flex: '1 1 100%',
      height: 20,
   }),
   menu: (defaultStyles) => ({
      ...defaultStyles,
      zIndex: 2,
      lineHeight: '1.6rem',
   }),
   multiValue: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#f3f3f3',
      borderRadius: 5,
      fontSize: '0.9rem',
      lineHeight: '1.6rem',
      margin: 0,
      marginTop: 5,
      marginRight: 5,
   }),
   placeholder: (defaultStyles) => ({
      ...defaultStyles,
      alignSelf: 'center',
      color: '#96999b',
      fontSize: '0.9rem',
      marginLeft: 0,
      marginTop: -2,
      marginBottom: 0,
      width: '100%',
      paddingBottom: 0,
   }),
   singleValue: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: '#f3f3f3',
      borderRadius: 5,
      fontSize: '0.8rem',
      lineHeight: '1.1rem',
      margin: 0,
      marginTop: 6,
      marginRight: 5,
      paddingLeft: 7,
      paddingRight: 7,
      maxWidth: 'calc(100% - 6px)',
   }),
   valueContainer: (defaultStyles) => ({
      ...defaultStyles,
      alignItems: 'center',
      display: 'flex',
      padding: 0,
      paddingLeft: '0.5rem',
      maxHeight: '3rem',
      height: '3rem',
   }),
}

export default styles
