import React, { memo, MouseEvent, useCallback, useMemo, useState } from 'react'
import * as styles from './imageCard.module.scss'
import { ImageCardProps } from './types'
import Svg from '../svg/svg'

const ImageCard = ({
   src,
   alt,
   isPrimaryImage,
   isMatchedImage,
   onImageClick,
   onDeleteClick,
}: ImageCardProps) => {
   const placeholderSrc = '/images/placeholder.svg'
   const [imageSrc, setImageSrc] = useState<string>(src ?? placeholderSrc)

   const handleDeleteClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
         e.stopPropagation()
         onDeleteClick()
      },
      [onDeleteClick]
   )

   const stateClass = useMemo(
      () => (isPrimaryImage ? styles.greenBorder : ''),
      [isPrimaryImage]
   )

   const onError = useCallback(
      () => (src !== placeholderSrc ? setImageSrc(placeholderSrc) : null),
      []
   )

   return (
      <article
         className={`${styles.imageCard} ${stateClass}`}
         onClick={onImageClick ? onImageClick : null}
      >
         {onDeleteClick && (
            <div
               className={styles.closeButton}
               onClick={handleDeleteClick}
               role="button"
               tabIndex={0}
            >
               <Svg
                  id="close"
                  overrideViewBox="0,0,8,8"
                  height="22"
                  width="22"
               />
            </div>
         )}
         <picture>
            <source srcSet={imageSrc} type="image/webp" />
            <source srcSet={imageSrc} type="image/png" />
            <source srcSet={imageSrc} type="image/jpeg" />
            <source srcSet={imageSrc} type="image/svg+xml" />
            <img
               alt={alt}
               src={imageSrc}
               width={100}
               height={100}
               onError={onError}
            />
         </picture>
         {isMatchedImage && (
            <div className={styles.icon}>
               <Svg id="matchedImage" fill="white" />
            </div>
         )}
      </article>
   )
}

export default memo(ImageCard)
